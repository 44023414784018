*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

a{
  text-decoration: none;
  text-transform: none;
}






.ValidationAccount {
  max-width: 600px;
  text-align: "center";
  margin: auto;
  margin-top: 10%;
}

.Centered{
  text-align: center;
}
#ImgLogo{
  width: "50px";
}